import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { httpToUse, ipAdress, localIpAdress, portToUse } from '../data/getAxiosRequests';
import { useLocation, useNavigate } from 'react-router-dom';
import { csrftoken } from '../constants/helpers';
import IconButton from '../components/UI/IconButton';
import { SVGs } from '../constants/Svgs';
import CustomButton from '../components/UI/CustomButtonBO';
import CustomButtonBO from '../components/UI/CustomButtonBO';

const PasswordForgottenPage = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const uid = queryParams.get('uid');
    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordConfirmationShown, setPasswordConfirmationShown] = useState(false);
    const navigate = useNavigate()
    
    const togglePasswordVisibility = () => {
        setPasswordShown(!passwordShown);
    };

    const togglePasswordConfirmationVisibility = () => {
        setPasswordConfirmationShown(!passwordConfirmationShown);
    };

    const formik = useFormik({
        initialValues: {
            newPassword: '',
            confirmPassword: ''
        },
        validationSchema: Yup.object({
            newPassword: Yup.string()
            .min(8, 'Le mot de passe doit contenir au moins 8 caractères')
            .matches(/[A-Z]/, 'Le mot de passe doit contenir au moins une majuscule')
            .matches(/[0-9]/, 'le mot de passe doit contenir au moins un chiffre')
            .matches(/[!@#$%^&*(),.?":{}|<>]/, 'Le mot de passe doit contenir au moins un caractère spécial')
            .required('Le mot de passe est requis'),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('newPassword'), null], "La confirmation du mot de passe n'est pas identique")
                .required('La confirmation du mot de passe est requise')
        }),
        onSubmit: (values) => {
            // Replace with your API endpoint and necessary payload
            axios.defaults.headers.common['X-CSRFToken'] = csrftoken
            axios.post(`https://rotpunkttest.fr/api/change-password/?token=${token}&uid=${uid}`, {
            new_password: values.newPassword,
            confirm_password: values.confirmPassword
        })
        .then(response => {
            navigate('/password-changed/')
            // Redirect or perform other actions upon success
        })
        .catch(error => {
            console.error('Error updating password', error);
        });
        },
    });

    return (
        <div className='password_reset_outercontainer'>
            <div className='password_reset_container'>
                <div className='password_reset_title_description'> 
                    <h2 className='password_reset_title'>Changement de mot de passe</h2>
                    <p className='password_reset_description'>Saisis et confirme ton mot de passe pour qu’il soit modifié</p>
                </div>
                <form className='password_reset_container_form' onSubmit={formik.handleSubmit}>
                    <div className='password_reset_container_form_full_formik_validation'>
                        <div className={formik.touched.newPassword && formik.errors.newPassword ? 'password_reset_container_form_input_icon_container_error' : 'password_reset_container_form_input_icon_container' }>
                            <input 
                                type={passwordShown ? "text" : "password"}
                                name="newPassword"
                                value={formik.values.newPassword}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                placeholder="Mot de passe"
                                className={(formik.touched.newPassword && formik.errors.newPassword) && 'error_input'}
                                onFocus={() => formik.setFieldError('newPassword', '')}
                                
                            />
                            {(formik.touched.newPassword && formik.errors.newPassword) &&
                                <IconButton 
                                    text=''
                                    Svg={SVGs.warningIcon}
                                />
                            }
                            <CustomButtonBO
                                text=''
                                Svg={passwordShown ? SVGs.openEye : SVGs.closedEye}
                                onClickProp={togglePasswordVisibility}
                                customStyle={'eye_layout'}

                            /> 
                        
                        </div>
                        {formik.touched.newPassword && formik.errors.newPassword ? (
                            <div className='error_password_message'>{formik.errors.newPassword}</div>
                        ) : null}
                    </div>
                    <div className='password_reset_container_form_full_formik_validation'>
                        <div className={'special_password_confirmation_container' }>
                            <div className={formik.touched.confirmPassword && formik.errors.confirmPassword ? 'password_reset_container_form_input_icon_container_error' : 'password_reset_container_form_input_icon_container'}>
                                <input 
                                    type={passwordConfirmationShown ? "text" : "password"}
                                    name="confirmPassword"
                                    value={formik.values.confirmPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    placeholder="Confirmation mot de passe"
                                    className={(formik.touched.confirmPassword && formik.errors.confirmPassword) && 'error_input'}
                                    onFocus={() => formik.setFieldError('confirmPassword', '')}
                                />
                                {(formik.touched.confirmPassword && formik.errors.confirmPassword) &&
                                    <IconButton 
                                        text=''
                                        Svg={SVGs.warningIcon}
                                    />
                                }
                                <CustomButtonBO
                                    text=''
                                    Svg={passwordConfirmationShown ? SVGs.openEye : SVGs.closedEye}
                                    onClickProp={togglePasswordConfirmationVisibility}
                                    customStyle={'eye_layout'}
                                    
                                /> 
                            </div>

                            {!formik.errors.confirmPassword &&  
                                <div className='password_indication'>* Le mot de passe doit contenir au moins : 1 majuscule, 1 caractère spécial et 1 chiffre</div>
                            }
                        </div>
                        {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                            <div className='error_password_message'>{formik.errors.confirmPassword}</div>
                        ) : null}
                    </div>
                    <button 
                        className={`${formik.isValid && formik.dirty && !formik.isSubmitting ? 'confirm_password_change' : 'confirm_password_change_disabled'}`}
                        type="submit"
                        disabled={!(formik.isValid && formik.dirty && !formik.isSubmitting)}
                    >
                        Changer de mot de passe
                        
                    </button>
                </form>
            </div>
        </div>
    );
}

export default PasswordForgottenPage;