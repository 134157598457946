import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { getRankingInformations, ipAdress, loginUser } from '../data/getAxiosRequests';
import { useLocation, useNavigate } from 'react-router-dom';
import { csrftoken } from '../constants/helpers';
import IconButton from '../components/UI/IconButton';
import { SVGs } from '../constants/Svgs';

import { RankingLineProps } from '../constants/types';
import RankingTab from '../components/party/RankingTab';
import RankingTabOneChallenge from '../components/party/RankingTabOneChallenge';
import CustomButton from '../components/UI/CustomButton';
import { TailSpin } from 'react-loader-spinner';
import { Colors } from '../constants/Consts';

const 
ChallengesScreen = () => {

    useEffect(() => {
        document.title = "Défis Gaston"},[])
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');
    const uid = queryParams.get('uid');
    const navigate = useNavigate()
    
    // States for storing ranking data
    const [rankingVersatile, setRankingVersatile] = useState<RankingLineProps[]>([]);
    const [rankingStrongest, setRankingStrongest] = useState<RankingLineProps[]>([]);
    const [rankingPosts, setRankingPosts] = useState<RankingLineProps[]>([]);
    const [isAutoRefreshed, setIsAutoRefreshed] = useState(true);  // Control auto-refresh
    const [showData, setShowData] = useState(false); 
    const [countSessions, setCountSessions] = useState(0)
    const timeLimit = new Date(2025, 0, 30, 23, 59, 0, 0);  // Note: month is 0-indexed, so 5 is June
    const [currentTime, setCurrentTime] = useState(new Date())


    function getTimeLeft(currentTime: Date): string {
        const diff = timeLimit.getTime() - currentTime.getTime();
    
        if (diff < 0) {
            return "C'est terminé ! Place aux récompenses !!";
        }
    
        // Convert milliseconds to minutes, hours, and days
        const minutesTotal = Math.floor(diff / 60000);
        const hoursTotal = Math.floor(minutesTotal / 60);
        const days = Math.floor(hoursTotal / 24);
        const hours = hoursTotal % 24;
        const minutes = minutesTotal % 60;
    
        // Format the response based on the duration
        if (days > 0) {
            const daysText = days === 1 ? "jour" : "jours";
            const hoursText = hours === 1 ? "heure" : "heures";
            return `${days} ${daysText}, ${hours} ${hoursText} et ${minutes} ${minutes === 1 ? "minute" : "minutes"}`;
        } else if (hours > 0) {
            const hoursText = hours === 1 ? "heure" : "heures";
            const minutesText = minutes === 1 ? "minute" : "minutes";
            return `${hours} ${hoursText} et ${minutes} ${minutesText}`;
        } else {
            if (minutes === 0) {
                return "1 minute";
            } else {
                const minutesText = minutes === 1 ? "minute" : "minutes";
                return `${minutes} ${minutesText}`;
            }
        }
    }
    

    useEffect(() => {
        // Function to update 'now'
        if(currentTime < timeLimit) {
            const updateNow = () => {
                const latestTime = new Date();
                setCurrentTime(latestTime);
            };

        // Set an interval to update 'now' every second
            const intervalId = setInterval(updateNow, 60000);
        

        // Cleanup function to clear interval
            return () => clearInterval(intervalId);
        }
        else{
            setIsAutoRefreshed(false)
        }
    }, [currentTime])

    // Function to fetch all ranking data
    const fetchRankingData = async () => {
        try {
            const data = await getRankingInformations();
            setRankingStrongest(data.strongest)
            setRankingVersatile(data.female)
        } catch (error) {
            console.error('Failed to fetch ranking data:', error);
        }
    };

    useEffect(() => {
        fetchRankingData().then(()=>setShowData(true));  // Initial fetch
    }, []);

    // useEffect to handle data fetching and auto-refresh every 20 seconds
    useEffect(() => {
        if (isAutoRefreshed) {
            fetchRankingData();  // Initial fetch
            const intervalId = setInterval(fetchRankingData, 60000);  // Set up interval

            return () => clearInterval(intervalId);  // Cleanup interval on component unmount
        }
    }, [isAutoRefreshed]);


    return (
        <div className='party_outercontainer_bis'>
            <div className='party_header_bis'>
               
                <div style={{alignItems:'center'}}>
                    <p className='headings-nohemi-2 title_party_header_bis'>Défi KV Vertical - du 16 au 30 janvier</p>
                    <p className='headings-nohemi-4 title_party_header_bis'>Grimpe un maximum de dénivelé avec Gaston pour t'approcher du kilomètre vertical et remporter des lots Gaston et la Sportiva. Seuls les blocs réussis comptent !</p>
                </div>
                
            </div>
            <div className='party_subHeader'>
                <div className='party_subHeader_component'>
                 
                    {timeLimit.getTime() - currentTime.getTime() <= 0  ?
                        <p className='headings-nohemi-4 large3 title_party_header_bis'>C'est terminé ! Place aux récompenses !!</p>
                        :
                        <p className='headings-nohemi-4 large3 title_party_header_bis'>Encore {getTimeLeft(currentTime)} pour valider tes blocs !</p>
                    }
                </div>
                
            </div>
            <div className='party_ranking_section_bis'>
                <div className='party_ranking_tabs_section_container_bis'>
                    <div className='party_ranking_tabs_section_upper_section_bis'>
                        <CustomButton
                            text=''
                            Svg={SVGs.crown}
                        />
                        <div className='party_ranking_tabs_text regular large2'>
                            <div className='headings-nohemi-4-bold'>On vise le sommet</div>
                            <div className='regular light party_ranking_tabs_subtext'>
                                <div>Valide un maximum de blocs pour un maximum de D+. Les mêmes blocs peuvent êtres grimpés plusieurs fois !</div>
                            </div>
                        </div>
                    </div>
                    {showData ?
                    
                        <div className='party_ranking_tabs_section'>
                            <RankingTabOneChallenge
                                lines={rankingStrongest}
                                shouldShowPoints={true}
                                shouldPointsBeTransformed={false}
                                category='KV'
                            />
                           
                        </div>
                        
                   
                        :
                  
                            <div >
                                <TailSpin color={Colors.colorprimary400} height={80} width={80} />
                            </div>
                         
                    }
                </div>
                {/* 
                <div className='party_ranking_tabs_section_container_bis'>
                    <div className='party_ranking_tabs_section_upper_section_bis'>
                        <CustomButton
                            text=''
                            Svg={SVGs.crown}
                        />
                        <div className='party_ranking_tabs_text regular large2'>
                            <div className='headings-nohemi-4-bold'>On vise le sommet (F)</div>
                            <div className='regular light party_ranking_tabs_subtext'>
                                <div>Valide tes blocs sur Gaston jusqu'au 22 décembre. Chaque bloc te rapporte 1000 points divisé par le nombre de grimpeurs l'ayant réussi</div>
                            </div>
                        </div>
                    </div>
                    {showData ?
                    
                        <div className='party_ranking_tabs_section'>
                            <RankingTabOneChallenge
                                lines={rankingVersatile}
                                shouldShowPoints={true}
                                shouldPointsBeTransformed={false}
                                category='Bêta'
                            />
                        </div>
                        
                
                        :
                  
                            <div >
                                <TailSpin color={Colors.colorprimary400} height={80} width={80} />
                            </div>
                         
                    }
                </div>
              
                <div className='party_ranking_tabs_section_container_bis'>
                    <div className='party_ranking_tabs_section_upper_section_bis'>
                        <CustomButton
                            text=''
                            Svg={SVGs.dice}
                        />
                        <div className='party_ranking_tabs_text regular large2'>
                            <div className='headings-nohemi-4-bold'>La séance d'or</div>
                            <div className='regular light party_ranking_tabs_subtext'>
                                <div>Rentre ta séance sur Gaston pour avoir une chance de gagner. On tire une personne au sort !</div>
                            </div>
                        </div>
                    </div>
                    {showData ?
                
                        <div className='party_ranking_tabs_section'>
                            <RankingTabOneChallenge
                                lines={rankingPosts}
                                shouldShowPoints={false}
                                shouldPointsBeTransformed={false}
                                category='Bêta'
                            />
                        </div>
                        
                  
                        :
                  
                            <div >
                                <TailSpin color={Colors.colorprimary400} height={80} width={80} />
                            </div>
                         
                    }
                </div>
                */}
            </div>

            
        </div>
    );
}

export default ChallengesScreen;